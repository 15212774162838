import * as yup from "yup";
import { TextFormField } from "../../../../components/textform/TextFormField.tsx";
import { Formik, Field, Form } from "formik";
import moment from "moment";

export const validation = yup.object().shape({
  messageSender: yup.string().required("Message sender is required"),
  messageRecipient: yup.string().required("Message recipient is required"),
  preparationDateAndTime: yup.string().required("Preparation date is required"),
  messageIdentification: yup
    .string()
    .required("Message identification is required"),
  messageType: yup.string().required("Message type is required"),
  correlationIdentifier: yup
    .string()
    .required("Correlation identifier is required"),
  MRN: yup.string().required("MRN is required"),
  additionalDeclarationType: yup
    .string()
    .required("Additional declaration type is required"),
  discrepanciesExistAtExit: yup
    .string()
    .required("Discrepancies exist at exit is required"),
  customsOfficeOfExitActual: yup
    .string()
    .required("Customs office of exit actual is required"),
  passageExitExitDate: yup
    .string()
    .required("Passage exit exit date is required"),
  personConfirmingExitRole: yup
    .string()
    .required("Person confirming exit role is required"),
  personConfirmingExitReferenceNumber: yup
    .string()
    .required("Person confirming exit reference number is required"),
  // declarationGoodsItemNumber: yup
  //   .string()
  //   .required("Declaration goods item number is required"),
});

export default function IE590({ submitForm, data }) {
  const form = [
    {
      name: "messageSender",
      label: "Message Sender",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageRecipient",
      label: "Message Recipient",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "preparationDateAndTime",
      label: "Preparation Date and Time",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageIdentification",
      label: "Message Identification",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "messageType",
      label: "Message Type",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "correlationIdentifier",
      label: "Correlation Identifier",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "MRN",
      label: "MRN",
      disabled: true,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "additionalDeclarationType",
      label: "Additional Declaration Type",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "manifestNumber",
      label: "Manifest Number",
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "discrepanciesExistAtExit",
      label: "Discrepancies Exist At Exit",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "customsOfficeOfExitActual",
      label: "Customs Office of Exit Actual",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "passageExitExitDate",
      label: "Passage Exit Exit Date",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "personConfirmingExitRole",
      label: "Person Confirming Exit Role",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    {
      name: "personConfirmingExitReferenceNumber",
      label: "Person Confirming Exit Reference Number",
      disabled: false,
      component: TextFormField,
      style: {
        width: "32%",
      },
    },
    // {
    //   name: "declarationGoodsItemNumber",
    //   label: "Declaration Goods Item Number",
    //   disabled: true,
    //   component: TextFormField,
    //   style: {
    //     width: "32%",
    //   },
    // },
  ];

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={
          data.message_type == "CC515C"
            ? {
                messageSender: data.message_sender,
                messageRecipient: data.message_recipient,
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageIdentification: data.message_identification,
                messageType: "CC590C",
                correlationIdentifier: data.message_identification,
                MRN: data.DECLARATION.mrn,
                additionalDeclarationType: data.additional_declaration_type,
                manifestNumber: "",
                discrepanciesExistAtExit: "",
                customsOfficeOfExitActual: data.customs_office_of_exit_declared,
                passageExitExitDate: moment().format("YYYY-MM-DD"),
                personConfirmingExitRole: "",
                personConfirmingExitReferenceNumber:
                  data.customs_office_of_exit_declared,
                // declarationGoodsItemNumber: data.GoodsItem.length.toString(),
              }
            : data.message_type == "CC570C"
            ? {
                messageSender: data.message_sender,
                messageRecipient: data.message_recipient,
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageIdentification: data.message_identification,
                messageType: "CC590C",
                correlationIdentifier: data.message_identification,
                MRN: data.DECLARATION.mrn,
                additionalDeclarationType: "",
                discrepanciesExistAtExit: "",
                customsOfficeOfExitActual:
                  data.customs_office_of_exit_declared_reference_number,
                passageExitExitDate: moment().format("YYYY-MM-DD"),
                personConfirmingExitRole: "",
                personConfirmingExitReferenceNumber:
                  data.customs_office_of_exit_declared_reference_number,
                // declarationGoodsItemNumber:
                //   data.consignment_item_declaration_goods_item_number,
              }
            : {
                messageSender: data.message_sender,
                messageRecipient: data.message_recipient,
                preparationDateAndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                messageIdentification: data.message_identification,
                messageType: "CC590C",
                correlationIdentifier: data.message_identification,
                MRN: data.DECLARATION.mrn,
                additionalDeclarationType: "",
                discrepanciesExistAtExit: "",
                customsOfficeOfExitActual:
                  data.customs_office_of_exit_declared_reference_number,
                passageExitExitDate: moment().format("YYYY-MM-DD"),
                personConfirmingExitRole: "",
                personConfirmingExitReferenceNumber:
                  data.customs_office_of_exit_declared_reference_number,
                // declarationGoodsItemNumber:
                //   data.consignment_item_declaration_goods_item_number,
              }
        }
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {() => (
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBlock: 50,
            }}
          >
            <div className="form-position">
              {form.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}
            </div>
            <button
              style={{
                paddingTop: 10,
                alignSelf: "center",
                width: "35%",
                paddingBlock: 10,
              }}
              className="submit-form"
              type="submit"
            >
              Submit IE590 Declaration
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
